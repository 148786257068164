import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

const SlideLogoForm = ({ image, text }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div
      className="bgCountent relative w-full h-auto"
      style={{
        backgroundImage: `url("${image ? image : rpdata?.stock?.[1]}")`,
      }}
    >
      <div className=" w-full h-auto">
        <div className="w-4/5 mx-auto flex flex-col justify-center z-50 -mt-10 md:-mt-0 my-auto">
          <div
            className="w-[80%] md:w-[30%] flex justify-center mx-auto"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="CompanyLogo"
              className="w-full flex"
            />
          </div>
          <div className="text-white text-center z-40">
            {/* <p>
                            {
                                rpdata?.dbValues?.[1]?.description
                            }     
                        </p> */}
            <p className="pb-3 text-white">
              {text ? (
                text
              ) : (
                <span>
                  {`para agregar el texto -> text={'description'}`}
                  <br />
                  {"para agregar lista de about -> listsAbout={true}"}
                  <br />
                  {"para agregar lista de servicios -> listsServices={true}"}
                </span>
              )}
            </p>
          </div>
          <div className="mx-auto">
            <ButtonContent btnStyle={"three"} />
          </div>
        </div>

        <img
          src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/beautyfulllandscapellc%20(1)%20(1).png?alt=media&token=6a10b44f-693d-4a59-9f3c-280461395a7f&_gl=1*1itby4g*_ga*MTg3NzIxNTgxMi4xNjczOTA2Mjk5*_ga_CW55HF8NVT*MTY4NTY1NDM1My4xOS4xLjE2ODU2NTQ2NDguMC4wLjA."
          alt="sello"
          className="absolute right-0 -bottom-10 w-[200px]"
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/QR.png?alt=media&token=a88e86bd-771d-4c46-b262-e70676f9f045&_gl=1*mmg0xw*_ga*MTg3NzIxNTgxMi4xNjczOTA2Mjk5*_ga_CW55HF8NVT*MTY4NTY1NDM1My4xOS4xLjE2ODU2NTQ5MDAuMC4wLjA."
          alt="sello"
          className="absolute left-5 -bottom-10 w-[120px] rounded-md"
        />
      </div>
    </div>
  );
};

export default SlideLogoForm;
